// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { SurveyCreatorWidget } from './surveyjs/SurveyCreatorWidget';
import { ContactForm } from './components/ContactForm';
import { Toaster } from 'react-hot-toast';
import Loading from './components/Loading';
import './styles.css';
import Addsql from './components/Addsql';
import { getUrlParams } from './config/urlParams';
import { verifySurvey, verifySegment, verifyContact } from './config/api';

export default function App() {
  const [state, setState] = useState({
    loading: true,
    validSurvey: false,
    surveyData: null,
    segmentData: null,
    validSegment: false,
    contactForm: false,
  });

  useEffect(() => {
    const {
      surveyId,
      domainId,
      editSurvey,
      segmentId,
      editId,
      contactForm,
      editContactForm,
    } = getUrlParams();

    const init = async () => {
      try {
        if ((surveyId || editSurvey) && domainId) {
          const surveyData = await verifySurvey(surveyId, editSurvey, domainId);
          if (surveyData) {
            setState(prev => ({ ...prev, surveyData, validSurvey: true }));
          }
        }

        if (segmentId || editId) {
          const segmentData = await verifySegment(segmentId, editId);
          if (segmentData) {
            setState(prev => ({ ...prev, segmentData, validSegment: true }));
          }
        }

        if (contactForm || editContactForm) {
          const isContactFormValid = await verifyContact(contactForm, editContactForm);
          console.log("verifying")
          if (isContactFormValid) {
            setState(prev => ({ ...prev, contactForm: true }));
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setState(prev => ({ ...prev, loading: false }));
      }
    };

    init();
  }, []);

  const { loading, validSurvey, surveyData, segmentData, validSegment, contactForm } = state;

  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          {loading ? (
            <Route exact path="/">
              <Loading />
            </Route>
          ) : validSurvey ? (
            <Route exact path="/">
              <SurveyCreatorWidget surveyData={surveyData} />
            </Route>
          ) : validSegment ? (
            <Route exact path="/">
              <Addsql segmentData={segmentData} />
            </Route>
          ) : contactForm ? (
            <Route exact path="/">
              <ContactForm />
            </Route>
          ) : (
            <>
              <Route exact path="/">
                <Redirect to="/" />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    fontFamily: 'sans-serif',
                  }}
                >
                  Weclome to Survey Builder!!
                </div>
              </Route>
              <Route>
                <div>Page not found</div>
              </Route>
            </>
          )}
        </Switch>
        <Toaster />
      </div>
    </BrowserRouter>
  );
}
