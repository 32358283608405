import React, { Component } from "react";
import { Query, Builder, Utils as QbUtils } from "react-awesome-query-builder";
import "antd/dist/antd.css";
import "react-awesome-query-builder/lib/css/styles.css";
import "react-awesome-query-builder/lib/css/compact_styles.css";
import BootstrapConfig from "react-awesome-query-builder/lib/config/bootstrap";
import MaterialConfig from "react-awesome-query-builder/lib/config/material";
import MuiConfig from "react-awesome-query-builder/lib/config/mui";
import "../styles.css";
import toast from 'react-hot-toast';
const InitialConfig = MaterialConfig;

const config = {
  ...InitialConfig,
  fields: {
    doctor: {
      label: "Doctor",
      type: "text",
    },
    visit_date: {
      label: "Visit Date",
      type: "date",
    },
    visit_type: {
      label: "Visit Type",
      type: "text",
    },
    unit: {
      label: "Unit",
      type: "text",
    },
  },
};

const queryValue = { id: QbUtils.uuid(), type: "group" };

class Addsql extends Component {
  state = {
    tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
    config: config,
  };

  async componentDidMount() {
	const urlParams = new URLSearchParams(window.location.search);
	const editSegmentParam = urlParams.get("edit_segment");
  
	if (editSegmentParam) {
	  try {
		// Fetch initial data based on edit_segment parameter
		const response = await fetch(`${process.env.REACT_APP_API_URL}.editing_segment`, {
		  method: 'POST',
		  headers: {
			'Content-Type': 'application/json',
		  },
		  body: JSON.stringify({ editsegment: editSegmentParam }),
		});
  
		const data = await response.json();
		const datata=data.message.segment_object;
  
		if (datata) {
		  // Parse the string representation of the object
		  const segmentObject = JSON.parse(datata);
  
		  if (segmentObject && segmentObject.id) {
			this.setState({
			  tree: QbUtils.loadTree(segmentObject),
			});
		  }
		}
	  } catch (error) {
		console.error("Error fetching initial data:", error);
	  }
	} else {
	  // If no edit_segment parameter, initialize with a default tree
	  this.setState({
		tree: QbUtils.loadTree({ id: QbUtils.uuid(), type: "group", children: [] }),
	  });
	}
  }
  onChange = (immutableTree, config) => {
    this.setState({ tree: immutableTree, config: config });
    const jsonTree = QbUtils.getTree(immutableTree);
    this.setState({ jsonTree })
  };

  handleSave = () => {
    const urlParams = new URLSearchParams(window.location.search);
		const segmentId = urlParams.get("segment_id") || urlParams.get("edit_segment");
    const sqlQuery = QbUtils.sqlFormat(this.state.tree, this.state.config);
    const { tree, config, jsonTree} = this.state;
    const requestData = {
      segmentid: segmentId, // Replace with the actual segment ID
      sql_query: sqlQuery.replace(/[()]/g, ''),
      jsontree:jsonTree
    };

    fetch(`${process.env.REACT_APP_API_URL}.storing_segment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
          toast.success("Network Error!");
        }
        return response.json();
      })
      .then(data => {
        toast.success("Segment saved successfully!");
      })
      .catch(error => {
        console.error("Error:", error);
      });
  };

  render = () => (
    <div>
      <Query
        {...config}
        value={this.state.tree}
        onChange={this.onChange}
        renderBuilder={this.renderBuilder}
      />
      {this.renderResult(this.state)}
    </div>
  );

  renderBuilder = props => (
    <div className="query-builder-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 20px",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <h4 className="filterr">Filter By</h4>
        <button className="addd-btn" onClick={this.handleSave}>
          Save
        </button>
      </div>
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  );

  renderResult = ({ tree: immutableTree, config }) => (
    <div className="query-builder-result">
      <div style={{ marginLeft: 20, fontSize: 18 }}>
        SQL where:{" "}
        <pre>{JSON.stringify(QbUtils.sqlFormat(immutableTree, config))}</pre>
      </div>
    </div>
  );


}

export default Addsql;
