import React, { useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import { Serializer } from "survey-core";
import "survey-creator-core/survey-creator-core.min.css";
import toast from 'react-hot-toast';
import "survey-core/defaultV2.css";
import "survey-core/survey.i18n";
import { ComponentCollection } from "survey-core";
import { surveyLocalization } from "survey-core";

// for adding only one another language else comment it for showing all
surveyLocalization.supportedLocales = ["en","ar"];


import {
	getSurveyJSON,
	saveSurveyJSON,
	getSurveyName,
	saveSurveyName,
	getSurveyAndThemeData,
} from "../surveyjs/WebDataService";

Serializer.findProperty("survey", "title").isRequired = true;


export function ContactForm(props) {
	const creator = new SurveyCreator({
		showLogicTab: false,
		showThemeTab: false,
		showJSONEditorTab: false,
		showTranslationTab: true,
	});
	
	creator.toolbox.removeItem("ranking");
creator.toolbox.removeItem("checkbox");
creator.toolbox.removeItem("radiogroup");
// creator.toolbox.removeItem("dropdown");
creator.toolbox.removeItem("rating");
// creator.toolbox.removeItem("comment");
// creator.toolbox.removeItem("text");
creator.toolbox.removeItem("boolean");
creator.toolbox.removeItem("expression");
creator.toolbox.removeItem("file");
creator.toolbox.removeItem("matrix");
creator.toolbox.removeItem("matrixdropdown");
creator.toolbox.removeItem("matrixdynamic");
creator.toolbox.removeItem("multipletext");
creator.toolbox.removeItem("panel");
creator.toolbox.removeItem("paneldynamic");
creator.toolbox.removeItem("signaturepad");
creator.toolbox.removeItem("sortablelist");
creator.toolbox.removeItem("imagepicker");
creator.toolbox.removeItem("image");
creator.toolbox.removeItem("html");
creator.toolbox.removeItem("nps");
creator.toolbox.removeItem("csat");




	var theme = null;
	creator.saveThemeFunc = (saveNo, callback) => {
		window.localStorage.setItem("survey-theme-json", creator.theme);
		callback(saveNo, true);
		theme = JSON.stringify(creator.theme, null, 2);
	};

	creator.isAutoSave = true;
	var hello = null;
	creator.saveSurveyFunc = (saveNo, callback) => {
		const formattedJSON = JSON.stringify(creator.JSON, null, 2);
		hello = formattedJSON;
	};

	const id = useCallback(() => {
		let res = props.id;
		if (!res) return -1;
		return Number(res);
	}, [props.id]);

	// for editing the surveys and rendering their previous data
	useEffect(() => {
		const fetchData = async () => {
			try {
				const urlParams = new URLSearchParams(window.location.search);
				const domainId = urlParams.get("domain_id");
				const editSurveyId = urlParams.get("editsurvey");
				const editcontactForm = urlParams.get("edit_contact_form");

				if (editcontactForm) {
					const requestDatas = {
						editcontactform: editcontactForm,
					};

					const response = await fetch(
						`${process.env.REACT_APP_API_URL}.edit_contact_survey`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify(requestDatas),
						}
					);

					if (!response.ok) {
						throw new Error(`HTTP error! Status: ${response.status}`);
					}

					const data = await response.json();
					creator.JSON = JSON.parse(data.message.survey_json);
					creator.theme = JSON.parse(data.message.theme_json);
				} else {
					// Load survey definition by ID
					getSurveyJSON(id(), json => {
						const prevTitle = creator.survey.title;
						creator.JSON = json;
						if (!!prevTitle) {
							creator.survey.title = prevTitle;
						}
					});
					getSurveyName(id(), name => {
						creator.survey.title = name;
					});
				}
			} catch (error) {
				console.error("Error fetching survey and theme data:", error);
			}
		};

		fetchData(); // Call the async function to fetch data
	}, [id, creator]);

	const handleAPICall = () => {
		const urlParamss = new URLSearchParams(window.location.search);
		const editsurvey = urlParamss.get("editsurvey");
		const domainIdd = urlParamss.get("domain_id");
		const editcontactForm = urlParamss.get("edit_contact_form");
		if (editcontactForm) {
			if (hello) {
				const requestDatad = {
					contact_form: editcontactForm,
					pages: hello,
					theme: theme !== null && theme !== undefined ? theme : null,
				};
				fetch(`${process.env.REACT_APP_API_URL}.create_contact_form`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(requestDatad),
				})
					.then(response => response.json())
					.then(data => {
						toast.success("Survey saved successfully!");
					})
					.catch(error => {
						console.error("Error making POST request:", error);
						toast.error("Error saving survey!");
					});
			} else {
				console.error("Hello data is not available.");
			}
		}
		const urlParams = new URLSearchParams(window.location.search);
		const contact_form = urlParams.get("contact_form");
		if (contact_form) {
			if (hello) {
				const requestData = {
					contact_form: contact_form,
					pages: hello,
					theme: theme !== null && theme !== undefined ? theme : null,
				};


				fetch(`${process.env.REACT_APP_API_URL}.create_contact_form`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(requestData),
				})
					.then(response => response.json())
					.then(data => {
						toast.success("Survey saved successfully!");
					})
					.catch(error => {
						console.error("Error making POST request:", error);
						toast.error("Error saving survey!");
					});
			} else {
				console.error("Hello data is not available.");
			}
		}
	};

	const style = { height: "100vh" };

	return (
		<div style={style}>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					padding: "12px 19px 0px 20px",
				}}
			>
				<Link to="/">
					<img
						src="https://helloapps3494.b-cdn.net/wp-content/uploads/2022/01/Purple_PNG.png.bv_resized_desktop.png.bv.webp?bv_host=helloapps.io"
						alt="Back To List"
						style={{
							cursor: "pointer",
							borderRadius: "4px",
							padding: "1px 1px",
							maxWidth: "150px",
						}}
					/>
				</Link>
				<button
					onClick={handleAPICall}
					type="button"
					className="btn btn--primary back-btn"
				>
					Save Form
				</button>
			</div>
			<SurveyCreatorComponent creator={creator}></SurveyCreatorComponent>
		</div>
	);
}