// urlParams.js
export const getUrlParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      surveyId: urlParams.get('survey_id'),
      domainId: urlParams.get('domain_id'),
      editSurvey: urlParams.get('editsurvey'),
      segmentId: urlParams.get('segment_id'),
      editId: urlParams.get('edit_segment'),
      contactForm: urlParams.get('contact_form'),
      editContactForm: urlParams.get('edit_contact_form'),
    };
  };
  