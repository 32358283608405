// api.js
export const fetchData = async (url, body) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    return response.ok ? response.json() : Promise.reject(response);
  };
  
  export const verifySurvey = async (surveyId, editSurvey, domainId) => {
    const data = await fetchData(`${process.env.REACT_APP_API_URL}.survey_domain`, { survey_name: surveyId || editSurvey, domain_id: domainId });
    return data.message.success ? data : null;
  };
  
  export const verifySegment = async (segmentId, editId) => {
    const data = await fetchData(`${process.env.REACT_APP_API_URL}.segment_confirm`, { segment_name: segmentId || editId });
    return data.message.success ? data : null;
  };
  
  export const verifyContact = async (contactForm, editContactForm) => {
    const data = await fetchData(`${process.env.REACT_APP_API_URL}.verify_contact_form`, { contactform: contactForm || editContactForm });
    return data.message.success ? true : false;
  };
  